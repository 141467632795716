import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`design`, `designer`, `product design`]} />
    <h1 class="page-title">Howdy!</h1>
    <p class="lead">My name is Kyle&mdash;I’m a digital product designer.</p> 
    <p>I do the user experience &amp; interface at <a href="http://www.connexta.com">Connexta</a> in sunny Phoenix, Arizona.</p>
    <p><a href="https://www.linkedin.com/in/kylejbollinger">Previous work</a>. I <a href="https://twitter.com/whoakb">tweet</a>.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}> </div>
    
  </Layout>
)

export default IndexPage
